.headerText {
  padding: 1px;
}

.done {
  text-decoration: line-through;
  color: #acacac;
}
.errorMsg {
  text-align: center;
}
.right {
  text-align: right;
}
.blue {
  color: #2b96fe;
}

/* overrides */
.form-control {
  border-radius: 0px;
  border-color: #fff !important;
}
.addBtn {
  border-color: #fff !important;
}

.todoInput {
  border-color: rgb(45, 50, 121) !important;
}
